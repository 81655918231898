<template>
  <b-modal
    v-model="isShow"
    size="xl"
    centered
    title="Report Template Setting"
    hide-footer
  >
    <b-container>
      <b-row>
        <b-col cols="4">
          <div class="title mb-3">Master Columns</div>

          <b-row class="align-items-center row-gap-lg">
            <template v-for="item of masterColumn">
              <template v-if="item.display">
                <b-col cols="10" :key="'master-' + item.id + '1'">
                  <InputText
                    placeholder=""
                    disabled
                    :value="item.field_display"
                    class="mb-0"
                  />
                </b-col>
                <b-col cols="2" :key="'master-' + item.id + '2'">
                  <font-awesome-icon
                    icon="plus"
                    class="cursor-pointer"
                    @click="addColumn(item)"
                  ></font-awesome-icon> </b-col
              ></template>
            </template>
          </b-row>
        </b-col>
        <b-col cols="8" style="border-left: 1px solid whitesmoke">
          <div class="title mb-3">Columns</div>
          <b-row class="align-items-center row-gap-lg">
            <template v-for="(item, index) of column">
              <b-col cols="5" :key="item.master_field_id + '1'">
                <InputText
                  placeholder=""
                  class="mb-0"
                  disabled
                  :value="item.field_display"
                />
              </b-col>
              <b-col cols="5" :key="item.master_field_id + '2'">
                <InputText
                  placeholder=""
                  class="mb-0"
                  v-model="item.field_name"
                />
              </b-col>
              <b-col cols="2" :key="'master-' + item.master_field_id + '3'">
                <font-awesome-icon
                  icon="minus"
                  class="cursor-pointer text-error"
                  @click="removeColumn(item, index)"
                ></font-awesome-icon>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
      <div class="text-center mt-3 content-between">
        <b-button variant="custom-outline-primary" @click="isShow = false"
          >Cancel</b-button
        >
        <b-button variant="custom-primary" @click="submit"> Confirm </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  data() {
    return { isShow: false, column: [], masterColumn: [], delete_field: [] };
  },
  methods: {
    async show(id) {
      await this.getMasterData(id);
      this.getData(id);
      this.isShow = true;
    },
    async getData(id) {
      const resp = await this.axios(`/AutoReport/colum_setting/${id}`);
      this.column = resp.data.detail;
      let master = JSON.parse(JSON.stringify(this.masterColumn));
      for (const item of master) {
        if (resp.data.detail.find((el) => item.id == el.master_field_id)) {
          item.display = false;
        } else {
          item.display = true;
        }
      }
      this.masterColumn = master;
    },
    async getMasterData(id) {
      if (this.masterColumn.length > 0) return;
      const resp = await this.axios(`/AutoReport/colum_master/${id}`);
      this.masterColumn = resp.data.detail;
    },
    addColumn(item) {
      item.display = false;
      let temp = {
        master_field_id: item.id,
        field_id: 0,
        field_display: item.field_display,
        field_name: item.field_display,
        field_value: "",
        auto_report_id: item.auto_report_id,
      };
      this.column.push(temp);
    },
    removeColumn(item, index) {
      this.column.splice(index, 1);
      let temp = this.masterColumn.find((el) => el.id == item.master_field_id);
      if (item.Id != 0) {
        this.delete_field.push(item);
      }
      temp.display = true;
    },
    async submit() {
      let temp = [],
        deteleTemp = [];
      for (const i of this.column) {
        temp.push({
          Id: i.field_id, // if 0 == add new || else update column
          auto_report_field_id: i.master_field_id,
          field_name: i.field_name,
          field_value: i.field_display,
        });
      }
      for (const i of this.delete_field) {
        deteleTemp.push({
          Id: i.field_id, // if 0 == add new || else update column
          auto_report_field_id: i.master_field_id,
          field_name: i.field_name,
          field_value: i.field_display,
        });
      }
      let payload = {
        AutoReportId: this.column[0].auto_report_id,
        delete_field: deteleTemp,
        auto_report_field: temp,
      };
      this.$bus.$emit("showLoading");
      const resp = await this.axios.post(
        `/AutoReport/update_colum_auto_report`,
        payload
      );
      this.$bus.$emit("hideLoading");
      if (resp.data.result) this.successAlert(), (this.isShow = false);
      else this.errorAlert(resp.data.message);

      //   this.column = resp.data.detail;
    },
  },
};
</script>

<style></style>
